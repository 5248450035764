<template>
  <div class=" max-w-2xl mx-auto p-4">
    <div class="pb-6 border-b">
      <div class=" black95 text-2xl font-500 text-center">银行卡信息</div>
    </div>
    <!-- currency: 'currency',
    bank_account_holder_name: 'account_holder_name',
    bank_country: 'bank_country',
    account_number: 'account_number',
    bic_swift: 'bic_swift',
    default_beneficiary: false -->
    <a-form-model
    id="beneficiaryAddStepTwoForm"
    ref="beneficiaryAddStepTwoForm"
    :rules="StepTwoRules"
    :model="beneficiaryStore.formData"
     layout="horizontal"
    :label-col="{ span: 6 }"
    :wrapper-col="{ span: 14 }"
    >
    <a-form-model-item prop="currency" class="mt-4 black95 text-sm font-500" label="币种">
        <a-select
          size="default"
          show-search
          placeholder="选择币种"
          option-filter-prop="children"
          style="width: 100%"
          :filter-option="true"
          :not-found-content="null"
          v-model="beneficiaryStore.formData.currency"
          @select="(e)=>{handleSelect(e, 'currency')}"
        >
        <a-select-option v-for="item in uniqueCurrencies" :key="item.fullname" :value="item.currency">
        {{ item.currency  }}
      </a-select-option>
        </a-select>
      </a-form-model-item>
    <a-form-model-item prop="bank_account_holder_name" class="mt-4 black95 text-sm font-500" label="银行卡持有人名字">
        <a-input v-model="beneficiaryStore.formData.bank_account_holder_name"
        @change="(e)=>{handleInput(e, 'bank_account_holder_name')}"
        class="" size="default" placeholder="" />
      </a-form-model-item>
      <a-form-model-item prop="bic_swift" class="mt-4 black95 text-sm font-500" label="BIC/SWIFT">
        <a-input v-model="beneficiaryStore.formData.bic_swift"
        @change="(e)=>{handleInput(e, 'bic_swift')}"
        class="" size="default" placeholder="" />
      </a-form-model-item>
    <a-form-model-item prop="bank_country" class="mt-4 black95 text-sm font-500" label="银行所属国家">
        <a-select
          size="default"
          show-search
          placeholder="选择国家"
          option-filter-prop="children"
          style="width: 100%"
          :filter-option="true"
          :not-found-content="null"
          v-model="beneficiaryStore.formData.bank_country"
          @select="(e)=>{handleSelect(e, 'bank_country')}"
        >
        <a-select-option v-for="item in ccCountryList" :key="item.fullname" :value="item.shortname">
        {{ item.fullname  }}
      </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="account_number" class="mt-4 black95 text-sm font-500" label="银行卡号">
        <a-input v-model="beneficiaryStore.formData.account_number"
        @change="(e)=>{handleInput(e, 'account_number')}"
        class="" size="default" placeholder="" />
      </a-form-model-item>
      <a-form-model-item class="black95 text-sm font-500" label="是否默认收款人">
      <a-radio-group @change="(e)=> {
        handleRadio(e, 'default_beneficiary')
      }" v-model="beneficiaryStore.formData.default_beneficiary" name="radioGroup">
        <a-radio class=" text-base" :value="1">Yes</a-radio>
        <a-radio class=" text-sm" :value="0">No</a-radio>
      </a-radio-group>
      </a-form-model-item>
    </a-form-model>

    <div class="flex justify-evenly pt-4">
      <a-button @click="handleBack" type="danger" >
      上一步
    </a-button>
      <a-button @click="handleNext" type="primary" >
      下一步
    </a-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ccCountryList from '@/assets/cc/ccCountry.json'
import { StepTwoRules } from './form_rules'

export default {
  created () {
    this.ccCountryList = ccCountryList
    this.$emit('update:current', 1)
    this.uniqueCurrencies = this.getUniqueCurrencies()
  },
  data () {
    return {
      StepTwoRules,
      ccCountryList: [],
      uniqueCurrencies: []
    }
  },
  methods: {
    getUniqueCurrencies () {
      const uniqueCurrenciesSet = new Set()
      return this.ccCountryList.filter(option => {
        if (!uniqueCurrenciesSet.has(option.currency)) {
          uniqueCurrenciesSet.add(option.currency)
          return true
        }
        return false
      })
    },
    handleSelect (e, content) {
      this.ChangeFormAction({ content: e })
    },
    handleRadio (e, content) {
      const { value } = e.target
      this.ChangeFormAction({ content: value })
    },
    handleInput (e, content) {
      const { value } = e.target
      const _value = value.replace(/[\u4E00-\u9FA5]/g, '')
      this.ChangeFormAction({ content, value: _value })
    },
    handleCancel () {
      this.ResetFormAction()
      this.$router.push('/user')
    },
    handleBack () {
      this.$router.go(-1)
    },
    handleNext () {
      this.$refs.beneficiaryAddStepTwoForm.validate(
        (valid) => {
          if (valid) {
            this.$router.push('/user/beneficiary/add/step-three')
          } else {
            this.$message.error('请填写正确的信息')
            return false
          }
        }
      )
    },
    ...mapActions('ccBeneficiary', ['ChangeFormAction', 'ResetFormAction'])
  },
  computed: {
    beneficiaryStore () {
      return this.$store.state.ccBeneficiary
    }
  }
}
</script>

<style scoped>

</style>
